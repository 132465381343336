<template>
    <v-responsive width="100%" class="mx-auto">
        <v-divider class="mb-4"/>

        <v-text-field v-model="board.subject" label="제목" outlined hide-details persistent-placeholder clearable />

        <v-layout align-center class="mx-n2 mt-4">
            <v-flex xs12 md6 class="px-2">
                <v-text-field v-model="board.writer.name" label="작성자" outlined persistent-placeholder clearable hide-details />
            </v-flex>
            <v-flex shrink md6 class="px-2">
                <v-text-field v-model="board.writer.email" label="이메일" outlined persistent-placeholder clearable hide-details class="ma-0 pa-0" />
            </v-flex>
        </v-layout>

        <v-layout align-center class="mx-n2 mt-4">
            <v-flex xs12 md6 class="px-2">
                <v-password-field v-model="board.password" label="비밀번호" outlined persistent-placeholder clearable hide-details :disabled="!board.isSecret" />
            </v-flex>
            <v-flex shrink md6 class="px-2">
                <v-checkbox v-model="board.isSecret" label="비밀글" hide-details class="ma-0 pa-0" />
            </v-flex>
        </v-layout>

        <v-textarea v-model="board.content" label="문의 내용" outlined hide-details persistent-placeholder class="mt-4"/>

        <v-divider class="my-4"/>

        <v-file-input v-model="board.images[0]" label="첨부이미지" outlined prepend-icon="" accept="image/*" clearable hide-details persistent-placeholder />

        <v-divider class="my-4"/>

        <v-layout>
            <v-btn large dark color="grey" @click="cancel">취소</v-btn>
            <v-spacer/>
            <v-btn large color="primary" @click="save">등록</v-btn>
        </v-layout>
    </v-responsive>
</template>

<script>
import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";
import VPasswordField from "@/components/plugins/vuetify/v-password-field.vue";

export default {
	components: {
        VPasswordField
	},
    data() {
        return {
            board: {
                type: null,
                code: "question",
                
                subject: null,
                content: null,
                password: "",

                isSecret: false,
                
                writer: {
                    name: null,
                    email: null
                },

                images: new Array(5).fill(null),
            },
        }
    },
    mounted(){
        this.init();
    },
	methods : {
        async init(){
            if(this.accessToken){
                let { me } = await api.v1.me.profile.get();
                this.board.writer = { ...me };
            }
        },
        fileChange(index){
            this.files[index].name = event.target.files[0].name;
        },
        cancel(){
            this.$router.go(-1);
        },
        validate(){
            try{
                var board = this.board;
                // if(!board.type) throw new Error("문의유형을 선택해주세요");
                if(!board.subject) throw new Error("문의제목을 입력해주세요");
                if(!board.content) throw new Error("문의내용을 입력해주세요");
                if(!board.writer.name) throw new Error("작성자 이름을 입력해주세요");
                if(board.isSecret && !board.password) throw new Error("비밀번호를 입력해주세요");

                if(!board.content) throw new Error("문의내용을 입력해주세요");
                if(!board.content) throw new Error("문의내용을 입력해주세요");

                return true;
            }
            catch(error){
                alert(error.message);
            }
            return false;
        },
        async save(){
            if(this.validate()){
                let { board } = await api.v1.boards.post({
                    ...this.board,
                    password: this.board.password ? CryptoAES.encrypt(this.board.password) : undefined,
                    images: []
                });

                for(let index in this.board.images){
                    let image = this.board.images[index];
                    if(image) await api.v1.boards.images.post(board._id, image, index);
                }

                alert("문의가 등록되었습니다");

                this.$router.go(-1);
            }
        },
	},
    computed: {
        accessToken(){
            return this.$store.state.accessToken || null;
        },
    }
}
</script>
