var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-responsive', {
    staticClass: "mx-auto",
    attrs: {
      "width": "100%"
    }
  }, [_c('v-divider', {
    staticClass: "mb-4"
  }), _c('v-text-field', {
    attrs: {
      "label": "제목",
      "outlined": "",
      "hide-details": "",
      "persistent-placeholder": "",
      "clearable": ""
    },
    model: {
      value: _vm.board.subject,
      callback: function ($$v) {
        _vm.$set(_vm.board, "subject", $$v);
      },
      expression: "board.subject"
    }
  }), _c('v-layout', {
    staticClass: "mx-n2 mt-4",
    attrs: {
      "align-center": ""
    }
  }, [_c('v-flex', {
    staticClass: "px-2",
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "작성자",
      "outlined": "",
      "persistent-placeholder": "",
      "clearable": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.writer.name,
      callback: function ($$v) {
        _vm.$set(_vm.board.writer, "name", $$v);
      },
      expression: "board.writer.name"
    }
  })], 1), _c('v-flex', {
    staticClass: "px-2",
    attrs: {
      "shrink": "",
      "md6": ""
    }
  }, [_c('v-text-field', {
    staticClass: "ma-0 pa-0",
    attrs: {
      "label": "이메일",
      "outlined": "",
      "persistent-placeholder": "",
      "clearable": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.writer.email,
      callback: function ($$v) {
        _vm.$set(_vm.board.writer, "email", $$v);
      },
      expression: "board.writer.email"
    }
  })], 1)], 1), _c('v-layout', {
    staticClass: "mx-n2 mt-4",
    attrs: {
      "align-center": ""
    }
  }, [_c('v-flex', {
    staticClass: "px-2",
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_c('v-password-field', {
    attrs: {
      "label": "비밀번호",
      "outlined": "",
      "persistent-placeholder": "",
      "clearable": "",
      "hide-details": "",
      "disabled": !_vm.board.isSecret
    },
    model: {
      value: _vm.board.password,
      callback: function ($$v) {
        _vm.$set(_vm.board, "password", $$v);
      },
      expression: "board.password"
    }
  })], 1), _c('v-flex', {
    staticClass: "px-2",
    attrs: {
      "shrink": "",
      "md6": ""
    }
  }, [_c('v-checkbox', {
    staticClass: "ma-0 pa-0",
    attrs: {
      "label": "비밀글",
      "hide-details": ""
    },
    model: {
      value: _vm.board.isSecret,
      callback: function ($$v) {
        _vm.$set(_vm.board, "isSecret", $$v);
      },
      expression: "board.isSecret"
    }
  })], 1)], 1), _c('v-textarea', {
    staticClass: "mt-4",
    attrs: {
      "label": "문의 내용",
      "outlined": "",
      "hide-details": "",
      "persistent-placeholder": ""
    },
    model: {
      value: _vm.board.content,
      callback: function ($$v) {
        _vm.$set(_vm.board, "content", $$v);
      },
      expression: "board.content"
    }
  }), _c('v-divider', {
    staticClass: "my-4"
  }), _c('v-file-input', {
    attrs: {
      "label": "첨부이미지",
      "outlined": "",
      "prepend-icon": "",
      "accept": "image/*",
      "clearable": "",
      "hide-details": "",
      "persistent-placeholder": ""
    },
    model: {
      value: _vm.board.images[0],
      callback: function ($$v) {
        _vm.$set(_vm.board.images, 0, $$v);
      },
      expression: "board.images[0]"
    }
  }), _c('v-divider', {
    staticClass: "my-4"
  }), _c('v-layout', [_c('v-btn', {
    attrs: {
      "large": "",
      "dark": "",
      "color": "grey"
    },
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v("취소")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "large": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("등록")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }